import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { BLink } from '../components/content-components'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { TagIcon, CalendarIcon } from '../components/icons'
import * as B from "../styles/blog.module.css"

const BlogPage = ({ data }) => {
	return  (
    <Layout keywords={data.mdx.frontmatter.tags} description={data.mdx.frontmatter.description} pageTitle={data.mdx.frontmatter.title} className={B.blog}>
			<h1 className="mb-0 ">{data.mdx.frontmatter.title}</h1>
      <div class="mb-5 mt-1 grid md:grid-flow-col md:auto-cols-max md:gap-2 items-center text-zinc-600">
        <div>
          <CalendarIcon className="w-5 h-5 inline-block mr-2" />
          {data.mdx.frontmatter.date}
        </div>
        <div>
          <TagIcon className="w-5 h-5 inline-block mr-2" />
            {data.mdx.frontmatter.tags.map((tag) => <BLink to={`/tags/${tag}`}>{tag}</BLink>).reduce((p, n) => [p, ', ', n])}
        </div>
      </div>
      <MDXRenderer>
        {data.mdx.body}
      </MDXRenderer>
    </Layout>
  )
}

export const q = graphql`
query blogQuery($id: String!) {
  mdx(id: {eq: $id}) {
    id
    body
    frontmatter {
      title
      date (formatString: "D MMMM YYYY")
			metaImage
			tags
    }
  }
}`

export default BlogPage